<template>
	<v-row>
		<v-col v-if="false">
			<v-card class="mb-3">
				<v-card-text class="primary--text">
					<v-slide-group v-model="model" center-active show-arrows>
						<v-slide-item v-slot="{ active, toggle }">
							<v-btn
								class="mx-1"
								:input-value="active"
								active-class="primary"
								depressed
								@click="toggle"
							>Mensal</v-btn>
						</v-slide-item>
						<v-slide-item v-slot="{ active, toggle }">
							<v-btn
								class="mx-1"
								:input-value="active"
								active-class="primary"
								depressed
								@click="toggle"
							>Diário</v-btn>
						</v-slide-item>
					</v-slide-group>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregandoSkeleton" />
			</v-card>
			<v-card class="mb-3" dark color="primary">
				<v-card-title>Bem-vindo, {{ usuario.nome }}</v-card-title>
			</v-card>
			<v-card v-if="model == 0">
				<v-card-text class="primary--text">
					<fieldset class="rounded pa-4 mb-3 fildset-border-dash">
						<legend class="px-2 text-h6">Realizado</legend>
						<v-row>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Minhas vendas</legend>
									<span class="text-h6 font-weight-bold">{{dashboard.vendas | formataDinheiro}}</span>
									<v-progress-linear
										class="mt-1 d-block d-md-none"
										v-model="dashboard.atingimento"
										height="20"
										rounded
										color="primary"
									>
										<strong
											v-if="dashboard.atingimento"
											class="white--text"
										>{{ dashboard.atingimento.toFixed(2) }} %</strong>
										<strong v-else class="white--text">0.00 %</strong>
									</v-progress-linear>
								</fieldset>
							</v-col>
              <v-col cols="12" md="4">
                <fieldset class="text-center rounded pa-2 fildset-border-dash-int">
                  <legend class="px-2">Minha meta</legend>
                  <span class="text-h6 font-weight-bold">{{dashboard.meta | formataDinheiro}}</span>
                </fieldset>
              </v-col>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Minha margem
                    <v-tooltip  bottom color="primary" content-class='custom-tooltip' class="pa-0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            size="20"
                            color="red"
                            class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <v-card
                          color="primary" elevation="0" style="min-width: 180px" class="pb-0"
                      >
                        <v-card-title class="white--text"><strong>Classificação Margem</strong></v-card-title>
                        <v-list color="primary" class="white--text pa-0">
                          <v-list-item class="mb-1 white--text red"> <strong> Abaixo de 18.00%  - Muito Baixa </strong></v-list-item>
                          <v-list-item class="mb-1 white--text orange darken-1"> <strong> Entre 18.01% até 24.00% - Baixa </strong></v-list-item>
                          <v-list-item class="mb-1 yellow accent-2"> <strong> Entre 24.01% até 28.00% - Média </strong></v-list-item>
                          <v-list-item class="mb-1 white--text light green"> <strong> Entre 28.01% até 31.00% - Boa  </strong></v-list-item>
                          <v-list-item class="mb-1 white--text blue darken-2"> <strong> Acima de 31.01%  - Muito Boa </strong></v-list-item>
                        </v-list>
                      </v-card>
                    </v-tooltip>
                  </legend>
                  <v-progress-linear
                      height="32"
                      rounded
                      :color="corProgresso(dashboard.margem)"
                      :value="volumeProgresso(dashboard.margem)"
                  >
                    <strong :class="volumeProgresso(dashboard.margem) <= 60 ? '' : 'white--text'" style="font-size: large">{{opcaoProgesso(dashboard.margem)}}</strong>
                  </v-progress-linear>
								</fieldset>
							</v-col>
							<v-col cols="12" class="d-none d-md-block">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Atingimento</legend>
									<v-progress-linear :value="dashboard.atingimento" height="32" rounded color="primary">
										<strong v-if="dashboard.atingimento" class="white--text" style="font-size: large">{{ dashboard.atingimento.toFixed(2) }} %</strong>
										<strong v-else class="white--text" style="font-size: large">0.00 %</strong>
									</v-progress-linear>
								</fieldset>
							</v-col>
						</v-row>
					</fieldset>
					<fieldset class="rounded pa-4 mb-3 fildset-border-dash">
						<legend class="px-2 text-h6">Atingimento</legend>
						<v-row>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Meu atingimento</legend>
									<span
										v-if="dashboard.atingimento"
										class="text-h6 font-weight-bold"
									>{{dashboard.atingimento.toFixed(2)}} %</span>
									<span v-else class="text-h6 font-weight-bold">0.00 %</span>
								</fieldset>
							</v-col>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Meu coeficiente</legend>
									<span
										v-if="dashboard.coeficiente"
										class="text-h6 font-weight-bold"
									>{{dashboard.coeficiente.toFixed(2)}} %</span>
									<span v-else class="text-h6 font-weight-bold">0.00 %</span>
								</fieldset>
							</v-col>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Meu salário</legend>
									<span class="text-h6 font-weight-bold">{{dashboard.comissao | formataDinheiro}}</span>
								</fieldset>
							</v-col>
						</v-row>
					</fieldset>
					<fieldset class="rounded pa-4 fildset-border-dash">
						<legend class="px-2 text-h6">Projeção</legend>
						<v-row>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Venda projetada</legend>
									<span class="text-h6 font-weight-bold">{{dashboard.projecao | formataDinheiro}}</span>
								</fieldset>
							</v-col>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Coeficiente projetado</legend>
									<span
										v-if="dashboard.coeficienteprojecao"
										class="text-h6 font-weight-bold"
									>{{dashboard.coeficienteprojecao.toFixed(2)}} %</span>
									<span v-else class="text-h6 font-weight-bold">0.00 %</span>
								</fieldset>
							</v-col>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Salário projetado</legend>
									<span class="text-h6 font-weight-bold">{{dashboard.salarioprojecao | formataDinheiro}}</span>
								</fieldset>
							</v-col>
						</v-row>
					</fieldset>
				</v-card-text>
			</v-card>
			<v-card v-if="model == 1">
				<v-card-text class="primary--text">
					<fieldset class="rounded pa-4 mb-3 fildset-border-dash">
						<legend class="px-2 text-h6">Realizado diário</legend>
						<v-row>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Minhas vendas</legend>
									<span class="text-h6 font-weight-bold">{{dashboard.vendasdia | formataDinheiro}}</span>
									<v-progress-linear
										class="mt-1 d-flex d-md-none"
										v-model="dashboard.atingimentodia"
										height="20"
										rounded
										color="primary"
									>
										<strong
											v-if="dashboard.atingimentodia"
											class="white--text"
										>{{ dashboard.atingimentodia.toFixed(2) }} %</strong>
										<strong v-else class="white--text">0.00 %</strong>
									</v-progress-linear>
								</fieldset>
							</v-col>
              <v-col cols="12" md="4">
                <fieldset class="text-center rounded pa-2 fildset-border-dash-int">
                  <legend class="px-2">Minha meta</legend>
                  <span class="text-h6 font-weight-bold">{{dashboard.metadia | formataDinheiro}}</span>
                </fieldset>
              </v-col>
							<v-col cols="12" md="4">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Minha margem
                    <v-tooltip  bottom color="primary" content-class='custom-tooltip' class="pa-0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            size="20"
                            color="red"
                            class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <v-card
                          color="primary" elevation="0" style="min-width: 180px" class="pb-0"
                      >
                        <v-card-title class="white--text"><strong>Classificação Margem</strong></v-card-title>
                        <v-list color="primary" class="pa-0">
                          <v-list-item class="mb-1 white--text red"> <strong> Abaixo de 18.00%  - Muito Baixa </strong></v-list-item>
                          <v-list-item class="mb-1 white--text orange darken-1"> <strong> Entre 18.01% até 24.00% - Baixa </strong></v-list-item>
                          <v-list-item class="mb-1 yellow accent-2"> <strong> Entre 24.01% até 28.00% - Média </strong></v-list-item>
                          <v-list-item class="mb-1 white--text light green"> <strong> Entre 28.01% até 31.00% - Boa  </strong></v-list-item>
                          <v-list-item class="mb-1 white--text blue darken-2"> <strong> Acima de 31.01%  - Muito Boa </strong></v-list-item>
                        </v-list>
                      </v-card>
                    </v-tooltip>
                  </legend>
                    <v-progress-linear
                        height="32"
                        rounded
                        :color="corProgresso(dashboard.margemdia)"
                        :value="volumeProgresso(dashboard.margemdia)"
                    >
                      <strong :class="volumeProgresso(dashboard.margemdia) <= 60 ? '' : 'white--text'" style="font-size: large">{{opcaoProgesso(dashboard.margemdia)}}</strong>
                    </v-progress-linear>
								</fieldset>
							</v-col>
							<v-col cols="12" class="d-none d-md-block">
								<fieldset class="text-center rounded pa-2 fildset-border-dash-int">
									<legend class="px-2">Atingimento</legend>
									<v-progress-linear v-model="dashboard.atingimentodia" height="32" rounded color="primary">
										<strong
											v-if="dashboard.atingimentodia"
											class="white--text" style="font-size: large"
										>{{ dashboard.atingimentodia.toFixed(2) }} %</strong>
										<strong v-else class="white--text" style="font-size: large">0.00 %</strong>
									</v-progress-linear>
								</fieldset>
							</v-col>
						</v-row>
					</fieldset>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col v-else>
			<v-card class="pa-5" color="primary" dark>
				<v-card-title class="mb-1">Bem-vindo, {{ usuario.nome }}</v-card-title>
				<v-card-text class="text-body-1">Para navegar pelo sistema selecione uma opção no menu acima</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
	name: "DashboardVendas",
	components: {},
	data: () => ({
    multiplicadorPercentual: 2,
		knowledge: 33,
		model: 0,
		dialog: { status: false, dados: [], header: {} },
		carregando: false,
		carregandoSkeleton: true,
		filiais: [],
		regionais: [],
		dashboard: {},
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idfiliais: [],
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
    corProgresso(per){
        if(per <= 18){
            return 'red'
        } else if(per <= 24){
            return 'orange darken-1'
        } else if(per <= 28){
            return 'yellow accent-2'
        } else if(per <= 31){
            return 'light green'
        } else if(per > 31){
            return 'blue darken-2'
        }
    },
    opcaoProgesso(per){
      if(per <= 18){
        return 'Muito Baixa'
      } else if(per <= 24){
        return 'Baixa'
      } else if(per <= 28){
        return 'Média'
      } else if(per <= 31){
        return 'Boa'
      } else if(per > 31){
        return 'Muito Boa'
      }
    },
    volumeProgresso(per){
      if(per <= 10) {
        return 5
      }else if(per <= 15){
        return 10
      }else if(per <= 18){
        return 15
      }else if(per <= 20){
        return 20
      } else if(per <= 24){
        return 30
      }else if(per <= 26){
        return 40
      } else if(per <= 28){
        return 60
      }else if(per <= 30){
        return 70
      } else if(per <= 31){
        return 80
      } else if(per <= 32){
        return 85
      }else if(per > 32) {
        return 95
      }
    },
		listarEmpresas() {
			this.busca.idfiliais = [];
			return axios
				.post(`${this.backendUrl}filial/listar`, {
					limit: 9999,
					offset: 0,
					idregional: this.busca.idregional || null,
					busca: null,
				})
				.then((res) => {
					this.filiais = res.data;
					this.filiais.lista = this.filiais.lista.filter((v) => {
						return (
							v.idfilial !== 6 &&
							v.idfilial !== 12 &&
							v.idfilial !== 22 &&
							v.idfilial !== 25 &&
							v.idfilial !== 29 &&
							v.idfilial !== 32 &&
							v.idfilial !== 33 &&
							v.idfilial !== 34 &&
							v.idfilial !== 37
						);
					});
				});
		},
		listarRegionais() {
			return axios
				.post(`${this.backendUrl}filial/regional/listar`)
				.then((res) => {
					this.regionais = res.data;
				});
		},
		get() {
			this.dashboard = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/principal`, {})
				.then((res) => {
					this.carregando = false;
					this.carregandoSkeleton = false;
					this.dashboard = res.data;
          this.dashboard.percentualProgresso = this.dashboard.margem * this.multiplicadorPercentual;
          this.dashboard.percentualProgressoDia = this.dashboard.margemdia * this.multiplicadorPercentual;
          this.dashboard.margemdia = 32;
				})
				.catch(() => {
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
		corMod1(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor;
				cor = this.corSemafaro(v[campo]);
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corMod2(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor = "#002D56";
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corSemafaro(valor) {
			let cor;
			if (valor >= 90) cor = "#002D56";
			if (valor < 90) cor = "#F2B90F";
			if (valor < 70) cor = "#951015";
			return cor;
		},
		shadeColor(color, percent) {
			var R = parseInt(color.substring(1, 3), 16);
			var G = parseInt(color.substring(3, 5), 16);
			var B = parseInt(color.substring(5, 7), 16);
			R = parseInt((R * (100 + percent)) / 100);
			G = parseInt((G * (100 + percent)) / 100);
			B = parseInt((B * (100 + percent)) / 100);
			R = R < 255 ? R : 255;
			G = G < 255 ? G : 255;
			B = B < 255 ? B : 255;
			var RR =
				R.toString(16).length == 1
					? "0" + R.toString(16)
					: R.toString(16);
			var GG =
				G.toString(16).length == 1
					? "0" + G.toString(16)
					: G.toString(16);
			var BB =
				B.toString(16).length == 1
					? "0" + B.toString(16)
					: B.toString(16);
			return "#" + RR + GG + BB;
		},
		async init() {
			await this.get();
		},
	},
	created() {
		this.init();
	},
};
</script>

<style>
.fildset-border-dash {
	border: 3px solid #023449;
}

.fildset-border-dash-int {
	border: 2px solid #023449;
}

.custom-tooltip {
  opacity: 1!important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 10px!important;
}
</style>